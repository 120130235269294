import React from 'react';
import Home from './Pages/Home';
import Formations from './Pages/Formations';
import Team from './Pages/Teams';
import Cafe from './Pages/Cafe';
import Revue from './Pages/Revue';
import Contact from './Pages/Contact';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/Formations" exact component={Formations} />
      <Route path="/L'équipe" exact component={Team} />
      <Route path="/Café Coaching" exact component={Cafe} />
      <Route path="/Références" exact component={Revue} />
      <Route path="/Contact" exact component={Contact} />
    </Switch>
  </BrowserRouter>
  );
}

export default App;
