import React from 'react';
import Fb from '../Assets/fb.png';
import Insta from '../Assets/insta.png'
import Linkdin from '../Assets/in.png'
import '../Css/Footer.css';

function Footer () {
  
    return(
        <div className="footer">
            <div>
               <a href="https://www.facebook.com/BARTHELEMY-CONSULTANTS-319219401426556"><img className="btn" src={Fb} alt=""  /> </a>
               <a href="https://www.instagram.com/barthelemyconsultants/?hl=fr"> <img className="btn" src={Insta} alt=""  /></a>
               <a href="https://www.linkedin.com/in/didier-barth%C3%A9l%C3%A9my-7581b9b5/"> <img className="btn" src={Linkdin} alt="" /></a>
            </div>
            <h1 className="contact">Contact</h1>
            <span className="number">&#x260E; : 06 76 81 43 41</span>
            <div><span className="mail">&#9993; : </span><a className="mailto" href="mailto:didier-barthelemy@wanadoo.fr">Didier-barthelemy@wanadoo.fr</a></div>
            <span className="mention" >Barthélémy Consultants © 2001 | @Mentions Légales</span>
        </div>
        
    )
}

export default Footer;