import React from "react";
import Didier from "../Assets/didier.jpg"
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import '../Css/Team.css';

function Team () {

    return(
<>
<Navbar/>
        <div className="lequipeC">
            <h1 className="titles">Notre équipe</h1>
              <p className="text">
                  Tous les stages et séminaires proposés par Barthélémy Consultants* (*certifié Qualité DATADOCk) sont animés 
                  directement par une équipe de consultants formateurs spécialisés par domaine. L’intervenant Barthélémy Consultants 
                  cumule à la fois une expertise forte dans son domaine et une très bonne connaissance du monde de l’entreprise.
                </p>
            <div className="equipe">
                <h1 className="titlesS" >Didier BARTHÉLÉMY</h1>
                <p className="direct"> 
                Directeur, Fondateur du Cabinet BARTHELEMY CONSULTANTS
                </p>
                
                <p className="textS">
                Consultant-formateur depuis 20 Ans auprès de nombreuses grandes entreprises comme : LVMH, DISNEY, CARREFOUR, IKÉA...
                Titulaire d’un Diplôme Universitaire de Technologie : Techniques de Commercialisation. Formé aux techniques de coaching et de Management à l’ESCP Europe.
                Ancien Manager Commercial et Responsable de L’Institut de Formation Commerciale chez Groupama.
                Expert de la relation client et du management, il intervient régulièrement  à L’Université de REIMS et dans divers Ecoles Supérieures de Commerce comme NÉOMA, l'ESC de TROYES...
                </p>
                <img className="didier" src={Didier} alt="" />
            </div>
        </div>
        <Footer/>
        </>
    )
}

export default Team;