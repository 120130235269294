/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Logo from '../Assets/LOGO.png'
import { useState } from 'react';
import { useHistory} from 'react-router';
import '../Css/Navbar.css';

function Navbar () {
   const [showLink , setShowLink] = useState (false);
   function handleShowLinks (){
     setShowLink(!showLink)
   }
    const history = useHistory();
    function handleClick() {
      history.push("/");
    }
    
    function FormClick() {
      history.push("/Formations");
    }
    function EquipeClick() {
      history.push("/L'équipe");
    }
  function CafeClick() {
      history.push("/Café Coaching");
    }
    function RefClick() {
      history.push("/Références");
    }
    function ContClick() {
      history.push("/Contact");
    }
    return(
        <nav className={`navbar ${showLink ? "show-nav" : "hidden" }`}>
            <img className="logo" src={Logo} alt="logo" onClick={handleClick} />
        <div className="borderXwidth">
          <a className="F" onClick={FormClick}>Formations</a>
          <a className="E"  onClick={EquipeClick} >Votre Formateur</a>
          <a className="C" onClick={CafeClick} >Café Coaching</a>
          <a className="R" onClick={RefClick} >Références</a>
          <a className="C" onClick={ContClick} >Contact</a>
        </div>
        <button className="burger" onClick={handleShowLinks}>
          <span className="burger-nav"></span>
        </button>
        </nav>
        
    )
}

export default Navbar;