import React from "react";
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import Logo1 from '../Assets/references/abl-informatique.png'
import Logo2 from '../Assets/references/actaris.png'
import Logo3 from '../Assets/references/agf.png'
import Logo4 from '../Assets/references/axon.png'
import Logo5 from '../Assets/references/boehringer.png'
import Logo6 from '../Assets/references/cari.png'
import Logo7 from '../Assets/references/carrard.png'
import Logo8 from '../Assets/references/ccip.png'
import Logo9 from '../Assets/references/cesi.png'
import Logo10 from '../Assets/references/clubmed.png'
import Logo11 from '../Assets/references/cmma.png'
import Logo12 from '../Assets/references/delpharm.png'
import Logo13 from '../Assets/references/disney.png'
import Logo14 from '../Assets/references/edf.png'
import Logo15 from '../Assets/references/effort-remois.png'
import Logo16 from '../Assets/references/esctroyes.png'
import Logo17 from '../Assets/references/ffgolf.png'
import Logo18 from '../Assets/references/francetelecom.png'
import Logo19 from '../Assets/references/gan.png'
import Logo20 from '../Assets/references/ghmumm.png'
import Logo21 from '../Assets/references/groupama.png'
import Logo22 from '../Assets/references/ifts.png'
import Logo23 from '../Assets/references/ikea.png'
import Logo24 from '../Assets/references/iut.png'
import Logo25 from '../Assets/references/lanson.png'
import Logo26 from '../Assets/references/lvmh.png'
import Logo27 from '../Assets/references/mcdo.png'
import Logo28 from '../Assets/references/mercier.png'
import Logo29 from '../Assets/references/mission-locales.png'
import Logo30 from '../Assets/references/moet.png'
import Logo31 from '../Assets/references/mutualite.png'
import Logo32 from '../Assets/references/novancia.png'
import Logo33 from '../Assets/references/pole-emploi.png'
import Logo34 from '../Assets/references/reims-basket.png'
import Logo35 from '../Assets/references/reims-habitat.png'
import Logo36 from '../Assets/references/reims-ms.png'
import Logo37 from '../Assets/references/rte-france.png'
import Logo38 from '../Assets/references/ruinart.png'
import Logo39 from '../Assets/references/smurfit.png'
import Logo40 from '../Assets/references/soufflet.png'
import Logo41 from '../Assets/references/taittinger.png'
import Logo42 from '../Assets/references/taxisg7.png'
import Logo43 from '../Assets/references/trelleborg.png'
import Logo44 from '../Assets/references/veuve-cliquot.png'
import "../Css/Revue.css"

function Revue (){
    return(
        <>
        <Navbar/>
        <div className="content">
            <h1 className="titreR">Nos Références</h1>
        <div className="gridR">
            <img  className="icon" src={Logo1} alt="" />
            <img  className="icon" src={Logo2} alt="" />
            <img  className="icon" src={Logo3} alt="" />
            <img  className="icon" src={Logo4} alt="" />
            <img  className="icon" src={Logo5} alt="" />
            <img  className="icon" src={Logo6} alt="" />
            <img  className="icon" src={Logo7} alt="" />
            <img  className="icon" src={Logo8} alt="" />
            <img  className="icon" src={Logo9} alt="" />
            <img  className="icon" src={Logo10} alt="" />
            <img  className="icon" src={Logo11} alt="" />
            <img  className="icon" src={Logo12} alt="" />
            <img  className="icon" src={Logo13} alt="" />
            <img  className="icon" src={Logo14} alt="" />
            <img  className="icon" src={Logo15} alt="" />
            <img  className="icon" src={Logo16} alt="" />
            <img  className="icon" src={Logo17} alt="" />
            <img  className="icon" src={Logo18} alt="" />
            <img  className="icon" src={Logo19} alt="" />
            <img  className="icon" src={Logo20} alt="" />
            <img  className="icon" src={Logo21} alt="" />
            <img  className="icon" src={Logo22} alt="" />
            <img  className="icon" src={Logo23} alt="" />
            <img  className="icon" src={Logo24} alt="" />
            <img  className="icon" src={Logo25} alt="" />
            <img  className="icon" src={Logo26} alt="" />
            <img  className="icon" src={Logo27} alt="" />
            <img  className="icon" src={Logo28} alt="" />
            <img  className="icon" src={Logo29} alt="" />
            <img  className="icon" src={Logo30} alt="" />
            <img  className="icon" src={Logo31} alt="" />
            <img  className="icon" src={Logo32} alt="" />
            <img  className="icon" src={Logo33} alt="" />
            <img  className="icon" src={Logo34} alt="" />
            <img  className="icon" src={Logo35} alt="" />
            <img  className="icon" src={Logo36} alt="" />
            <img  className="icon" src={Logo37} alt="" />
            <img  className="icon" src={Logo38} alt="" />
            <img  className="icon" src={Logo39} alt="" />
            <img  className="icon" src={Logo40} alt="" />
            <img  className="icon" src={Logo41} alt="" />
            <img  className="icon" src={Logo42} alt="" />
            <img  className="icon" src={Logo43} alt="" />
            <img  className="icon" src={Logo44} alt="" />
        </div>
        </div>
        <Footer/>
        </>
    )
}

export default Revue;