import React from "react";
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import ReactContactForm from 'react-mail-form';
import '../Css/Contact.css';

function Contact (){
    return(
        <>
        <Navbar/>
        <div className="contactR">
        <h1 className="tC">Contact</h1>
        <ReactContactForm className="Formu" to="didier-barthelemy@wanadoo.fr" contentsPlaceholder='Une Question ?' titlePlaceholder='Objet' buttonText='Envoyer'/>
        </div>
        <Footer/>
        </>
    )
}

export default Contact;