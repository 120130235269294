import React from "react";
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import { useState } from "react";
import '../Css/Formations.css';

function Formations() {
    const [toggleTabs, setToggleTabs] = useState(1);
    const toggleTab = (index) => {
        setToggleTabs(index)
    }
  return (
      <>
      <Navbar/>
      <div className="container">
        <div className="onglets">
            <div onClick={() => toggleTab(1)} className={toggleTabs === 1 ? "tabs active-tabs" : "tabs"}>Commerce / Marketing / Relation Client</div>
            <div onClick={() => toggleTab(2)} className={toggleTabs === 2 ? "tabs active-tabs" : "tabs"}>Management / <br/>Communication</div>
            <div onClick={() => toggleTab(3)} className={toggleTabs === 3 ? "tabs active-tabs" : "tabs"}>Développement <br/>Personnel</div>
            <div onClick={() => toggleTab(4)} className={toggleTabs === 4 ? "tabs active-tabs" : "tabs"}>Formation <br/>sur Demande</div>
        </div>
        <div className="contenu-onglets">
            <div className={toggleTabs === 1 ? "contenu active-contenu" : "contenu"}>
                <h1 className="titleh">Commerce / Marketing / Relation Client :</h1>
                <ul className="ulform">
                    <li className="liform">Les clés de la relation client</li>
                    <li className="liform">Les étapes d'une négociation commercial </li>
                    <li className="liform">Perfectionnement aux techniques de vente</li>
                    <li className="liform">Conclure une négociation avec succès</li>
                    <li className="liform">La vente par téléphone</li>
                    <li className="liform">Dynamiser son réseau professionnel</li>
                    <li className="liform">Commerce pour chef d entreprise</li>
                    <li className="liform">Les clés du marketing</li>
                    <li className="liform">Le marketing opérationnel</li>
                    <li className="liform">Optimiser sa stratégie commerciale</li>
                    <li className="liform">Savoir bâtir un plan d action commerciale</li>
                    <li className="liform">Les techniques de vente les plus efficaces</li>
                    <li className="liform">Réaliser un mailing</li>
                    <li className="liform">Savoir gérer son temps</li>
                    <li className="liform">Savoir accueillir physiquement les clients</li>
                    <li className="liform">Savoir accueillir les clients au téléphone</li>
                    <li className="liform">Professionnaliser votre réception client</li>
                    <li className="liform">Savoir gérer les réclamations clients</li>
                    <li className="liform">Traiter efficacement les appels des clients difficiles</li>
                    <li className="liform">Gérer les conflits avec ses clients</li>
                    <li className="liform">La satisfaction du client</li>
                    <li className="liform">L’art de communiquer</li>
                    <li className="liform">Communiquer efficacement par téléphone</li>
                    <li className="liform">L’organisation dynamique d’un secteur commercial</li>
                    <li className="liform">Savoir vendre aux grands comptes</li>
                </ul>
                <p className="spc">
                    Pour connaître les objectifs ainsi que le détail du contenu des formations,
                    Veuillez contacter le 06 76 81 43 41 ou <br/> Envoyé un mail sur : didier-barthelemy@wanadoo.fr
                </p>
            </div>
            <div className={toggleTabs === 2 ? "contenu active-contenu" : "contenu"}>
                <h1 className="titleh">Management / Communication :</h1>
                <ul className="ulform">
                    <li className="liform">La cohésion d’équipe</li>
                    <li className="liform">Conduire le changement</li>
                    <li className="liform">Gérer les personnalités difficile de son équipe</li>
                    <li className="liform">Manager les nouvelles générations</li>
                    <li className="liform">Savoir convaincre en toute circonstance</li>
                    <li className="liform">Préparer sa retraite</li>
                    <li className="liform">Développer la confiance en soi</li>
                    <li className="liform">Le management transversal</li>
                    <li className="liform">Optimiser ses relations professionnelles</li>
                    <li className="liform">S’exprimer dans un groupe</li>
                    <li className="liform">Dynamiser et coacher son équipe commerciale</li>
                    <li className="liform">Manager en situation de crise</li>
                    <li className="liform">Savoir mener un entretien de recadrage</li>
                    <li className="liform">Savoir déléguer</li>
                    <li className="liform">Le coaching efficace</li>
                    <li className="liform">Prendre du recul pour mieux manager</li>
                    <li className="liform">Maitriser le temps et le stress de son équipe</li>
                    <li className="liform">Le management interculturel</li>
                    <li className="liform">Développer son leadership</li>
                    <li className="liform">Manager avec efficacité</li>
                    <li className="liform">La conduite de réunion niv 1 et niv 2</li>
                    <li className="liform">La formation de formateur niv 1 et niv 2</li>
                    <li className="liform">La formation des tuteurs</li>
                    <li className="liform">Prendre la parole en public</li>
                    <li className="liform">La conduite d’entretien individuel</li>
                    <li className="liform">La conduite de projet</li>
                    <li className="liform">Comment manager ses anciens collègues</li>
                </ul>
                <p className="spc">
                    Pour connaître les détails du contenu des formations,
                    Veuillez contacter le 06 76 81 43 41 ou <br/> Envoyé un mail sur : didier-barthelemy@wanadoo.fr
                </p >
            </div>
            <div className={toggleTabs === 3 ? "contenu active-contenu" : "contenu"}>
                <h1 className="titleh">Développement Personnel :</h1>
                <ul className="ulform">
                    <li className="liform">La gestion du stress</li>
                    <li className="liform">Savoir convaincre</li>
                    <li className="liform">Savoir dire non</li>
                    <li className="liform">Développer son aisance</li>
                    <li className="liform">Changer pour évoluer de façon positive</li>
                    <li className="liform">L’image de soi</li>
                    <li className="liform">Maitriser les situations conflictuelles</li>
                    <li className="liform">Prendre la parole avec brio</li>
                    <li className="liform">Développer son charisme</li>
                    <li className="liform">L’analyse transactionnelle</li>
                    <li className="liform">Impacter de façon positive</li>
                    <li className="liform">La gestion du temps</li>
                    <li className="liform">Préparer sa retraite</li>
                    <li className="liform">Prendre confiance en soi</li>
                    <li className="liform">Développer son assertivité</li>
                    <li className="liform">Prendre du recul en situation difficile</li>
                    <li className="liform">Savoir gérer les conflits</li>
                    <li className="liform">Devenir un véritable leader</li>
                    <li className="liform">L’art de la communication</li>
                    <li className="liform">L'estime de soi</li>
                </ul>
                <p className="spc">
                    Pour connaître les objectifs ainsi que le détail du contenu des formations,
                    Veuillez contacter le 06 76 81 43 41 ou <br/> Envoyé un mail sur : didier-barthelemy@wanadoo.fr
                </p>
            </div>
            <div className={toggleTabs === 4 ? "contenu active-contenu" : "contenu"}>
                <h1 className="titleh">Formation sur Demande :</h1>
                <ul className="ulforme">
                    <li className="liform">INTER</li>
                    <li className="liform">INTRA</li>
                    <li className="liform">DATADOCK Depuis janvier 2017, une formation doit, pour être financée, 
                    être réalisée par un organisme de formation référencé par le financeur. Autrement dit, 
                    il doit avoir répondu aux six critères de qualité définis par la loi du 5 mars 2014. 
                    C’est un gage de crédibilité tant pour les entreprises que pour les salariés bénéficiaires de formation</li>
                </ul>
                <p className="spc">
                    Pour connaître les objectifs ainsi que le détail du contenu des formations,
                    Veuillez contacter le 06 76 81 43 41 ou <br/> Envoyé un mail sur : didier-barthelemy@wanadoo.fr
                </p>
            </div>
        </div>
    </div>
    <Footer/>
    </>
  );
}

export default Formations;