import React from "react";
import CafeC from "../Assets/coffee.jpeg"
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import '../Css/Cafe.css';

function Cafe (){
    return(
        <>
        <Navbar/>
        <div className='centre'>
        <div className="Cafe">
           <img className="CafeImg" src={CafeC} alt="" />
           <div className="coffee">
            <h1 className="titleq">"LE CAFE COACHING ©" :</h1>
            <p className="text">Rencontre conviviale et décontractée avec des coachs expérimentés pour partager des moments forts de sa vie</p>
            <p className="text">Les sujets traités portent sur différents aspects de la pratique, 
                nous abordons différents domaines comme par exemple : La gestion du stress, l'affirmation de soi, la communication interpersonnelle,
                l’image de soi, la gestion du temps, la pensée magique...</p>
            <p className="text">"les cafés coaching ©" vous conseillent dans une ambiance chaleureuse pour mettre toutes les chances de votre côté !</p>
            <p className="text">Les lieux:  dans des cafés chics et lounges partout en France et en Europe, outre- atlantique. Villes :  
                PARIS, REIMS, STRASBOURG, GENÈVE LUXEMBOURG, BRUXELLES, Pays : SUISSE, BELGIQUE, LUXEMBOURG, QUEBEC ....</p>
            </div>
        </div>
        </div>
        <Footer/>
        </>
    )
}

export default Cafe;