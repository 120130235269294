/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Navbar from '../Components/Navbar.jsx';
import Footer from '../Components/Footer.jsx';
import Coach from "../Assets/coaching.png";
import Conseil from "../Assets/conseil.png";
import Formation from "../Assets/formation.png";
import Team from "../Assets/team_building.png";
import Ans from "../Assets/20ans.png";
import Data from "../Assets/datadock.jpg";
import Ref1 from "../Assets/references/lvmh.png";
import Ref2 from "../Assets/references/edf.png";
import Ref3 from "../Assets/references/disney.png";
import Ref4 from "../Assets/references/ikea.png";
import Ref5 from "../Assets/references/mcdo.png";
import { useHistory} from 'react-router';
import "../Css/Home.css";

function Home () {
  
  const history = useHistory();
  function RefClick() {
    history.push("/Références");
  }
  function FormationClick() {
    history.push("/Formations");
  }
    return(
    <>
    
      <Navbar/>
      <div className="margin">  
      <h1 className="BvnS" >Bienvenue sur le site de Barthélémy Consultants</h1>
       <a className="BAS">"Ensemble éveillons vos talents"</a>
       </div>
      <div className="title">
      <h1 className="Bvn" >Bienvenue sur le site de Barthélémy Consultants</h1>
      <a className="BA">"Ensemble éveillons vos talents"</a>
     <img className="Ans" src={Ans} alt="" />
    
        <p className="text">
        Barthélémy Consultants est un Organisme de Formation certifié DATADOCK spécialisé dans - La Relation Client, - Le Management et - Les Ressources Humaines depuis 20 ANS.
Le but est d’intervenir auprès des sociétés et entreprises pour développer le potentiel de leurs collaborateurs. Les interventions de Barthélémy Consultants ont pour objectif d’améliorer les comportements et favoriser les relations professionnelles.
        </p>
      </div>
      <div className="title">
        <h2 className="BCN" >Vos collaborateurs ont l’ambition d’évoluer et vous souhaitez valoriser leur potentiel... ?</h2>
        <p className="text2">Former, entraîner, accompagner vos collaborateurs dans un esprit commun de qualité et de service.
           REACTIVITE, ADAPTABILITE, QUALITE et SUIVI, sont les maîtres mots de BARTHELEMY CONSULTANTS.</p>
      <img className="Data" src={Data} alt="" /> 
      </div>
      <div className="Dref"><h1 className="Bref">Nos Références</h1></div>
      <div className="reference">
        <img className="ref" src={Ref1} alt="" />
        <img className="ref" src={Ref2} alt="" />
        <img className="ref" src={Ref3} alt="" />
        <img className="ref" src={Ref4} alt="" />
        <img className="ref" src={Ref5} alt="" />
       <button className="btnref" onClick={RefClick}>Plus de Références</button>
      </div>
      <div className="grid">
           <div className="coaching">
             <img className="imgC" src={Coach} alt="" />
             <div className="intituler">
               <h1 className="nom">Coaching</h1>
               <span className="sp">" Optimisation de la PERFORMANCE individuelle "</span>
               <span className="sp2" > Accompagne a l'efficacité commerciale , au management d'équipe,au développement d"entreprise et personnel.</span>
               <ul className="listC">
                  <li>- Vous souhaitez réussir un projet,</li>
                  <li>- Vous désirez vivre plus sereinement en étant en action,</li>
                  <li>- Vous voulez optimiser votre vie personnelle,</li>
                  <li>- Vous avez une décision ou une orientation à prendre,</li>
                  <li>- Vous devez faire face à de nouveaux défis ,</li>
                  <li>- Vous avez besoin d’améliorer vos relations ...</li>
               </ul>
             </div>
           </div>
           <div className="conseil">
             <img className="imgC" src={Conseil} alt="" />
             <div className="intituler">
               <h1 className="nom2">Conseil</h1>
               <span className="sp">" Structurer vos projets dans le domaine du DÉVELOPPEMENT "</span>
               <span className="sp2" > Accompagne a l'efficacité commerciale , au management d'équipe,au développement d"entreprise et personnel.</span>
               <p className="area">
                Les sociétés aujourd’hui doivent s’adapter en agissant sur des processus avec une démarche qui implique parfois des évolutions des métiers.
                Toutes les missions et interventions consistent à orienter et faciliter les mutations des entreprises vers des organisations et des processus axés sur le client et sur la performance.
                Chaque point est analysé et le diagnostic est suivi d’un conseil
               </p>
             </div>
           </div>
           <div className="formation">
             <img className="imgC" src={Formation} alt="" onClick={FormationClick}/>
             <div className="intituler">
               <h1 className="nom3">Formation</h1>
               <span className="sp">" Ces formations sont pratiques et conviennent pour CHAQUE SOCIÉTÉ "</span>
               <span className="sp2" >L'art de communiquer,développer son leadership, la satisfaction du client, l'accueil physique ...</span>
               <p className="area">
               Les formations se déroulent essentiellement sous forme de stage de 2 ou 3 jours en moyenne par module.
               Ces formations sont pratiques et conviennent pour chaque société.
               Les formations proposées permettent d’optimiser les performances.
               Le module de formation que vous choisissez est structuré de manière à permettre une progression régulière pour atteindre tous les niveaux de compétence souhaités.
               </p>
             </div>
           </div>
           <div className="team">
             <img className="imgC" src={Team} alt="" />
             <div className="intituler">
               <h1 className="nom4">Team Building</h1>
               <span className="sp">" Se dépasser pour atteindre la PERFORMANCE "</span>
               <span className="sp2" > Renforcer la cohésion d'équipe, favoriser l'appropriation des valeurs de votre management, bâtir une véritable culture d'entreprise , créer un état d'esprist positif.</span>
               <p className="area">
               Un team building permet de développer des valeurs collectives au sein d’une entreprise, comme la cohésion, l’interdépendance, ou l’implication. Grâce à des activités à caractère événementiel, souvent de nature collective et interactive, les participants cultivent leurs facultés à travailler en équipe.  
               Les jeux et les techniques créatives favorisent et stimulent l’imagination.
              </p>
             </div>
           </div>
      </div>
      <Footer/>
    </>
    )
}

export default Home;